
import { Component, Vue, Provide, Watch, Emit } from 'vue-property-decorator'
import { mobileRegExp, emailRegExp } from '@/utils/reg'
import httpHelper from '@/utils/request'
import configs from '@/configs'
import { getMBankAuth, setMBankAuth, setYiqianbaoAuthCode, setYiqianbaoSscExt, setYiqianbaoSource } from '@/utils/cookies'
import GlobalMixin from '@/mixins/global'
import getCurrentQuery from '@/utils/querystrings'

@Component({
  name: 'yiqianbao',
  mixins: [GlobalMixin]
})
export default class PartnerLoginPage extends Vue {
  @Provide() public ischeck: boolean = false
  @Provide() public showLogin: boolean = false

  @Emit()
  public select () {
    this.ischeck = !this.ischeck
  }

  @Emit()
  public async next () {
    const data:any = {
      authCode: getCurrentQuery('authCode') || ''
    }
    if (!this.ischeck) {
      this.$toasted.show('勾选下方同意后，才可去购物哦~')
      return false
    }
    setMBankAuth()
    try {
      const yiqianbao = await httpHelper.getAuthLogin(data, 'authorize/yiqianbaoapp')
      this.goTo(yiqianbao, 9, 15)
      location.href = 'https://m.benlai.com'
    } catch (err) {
      this.goTo(err, 9, 15)
      this.$toasted.show(err.msg)
    }
  }

  public async created () {
    let authCode = getCurrentQuery('authCode') || ''
    let sscExt = getCurrentQuery('sscExt') || ''
    setYiqianbaoAuthCode(authCode)
    setYiqianbaoSscExt(sscExt)
    setYiqianbaoSource('app')
    console.log('>>>>>>authCode', authCode)
    console.log('>>>>>>sscExt', sscExt)
    if (getMBankAuth()) {
      const data:any = {
        authCode: authCode
      }
      try {
        const yiqianbao = await httpHelper.getAuthLogin(data, 'authorize/yiqianbaoapp')
        this.goTo(yiqianbao, 9, 15)
        location.href = 'https://m.benlai.com'
      } catch (err) {
        this.goTo(err, 9, 15)
        this.$toasted.show(err.msg)
      }
    } else {
      this.showLogin = true
    }
  }
}
