
import { Component, Vue, Provide, Watch, Emit } from 'vue-property-decorator'
import { mobileRegExp, emailRegExp } from '@/utils/reg'
import httpHelper from '@/utils/request'
import configs from '@/configs'
import { getMBankAuth, setMBankAuth, setYiqianbaoAuthCode, setYiqianbaoSscExt, setYiqianbaoSource, setCountYqb, getCountYqb } from '@/utils/cookies'
import GlobalMixin from '@/mixins/global'
import getCurrentQuery from '@/utils/querystrings'

@Component({
  name: 'yiqianbao',
  mixins: [GlobalMixin]
})
export default class PartnerLoginPage extends Vue {
  @Provide() public ischeck: boolean = false
  @Provide() public showLogin: boolean = false

  @Emit()
  public select () {
    this.ischeck = !this.ischeck
  }

  @Emit()
  public async next () {
    const data:any = {
      authCode: getCurrentQuery('authCode') || ''
    }
    if (!this.ischeck) {
      this.$toasted.show('勾选下方同意后，才可去购物哦~')
      return false
    }
    setMBankAuth()
    try {
      const res = await httpHelper.getAuthLogin(data, 'authorize/yiqianbao')
      console.log('yiqianbao1=' + JSON.stringify(res))
      location.replace(`${httpHelper.mRoot}`)
    //  this.goTo(res, 9, 15)
    } catch (err) {
      console.log('yiqianbao1=' + JSON.stringify(err))
      // this.goTo(err, 9, 15)
      if (err.code === 104) {
        location.replace(`/bind?smstype=9&grantType=15`)
      }
      this.$toasted.show(err.msg)
    }
  }

  public async created () {
    let authCode = getCurrentQuery('authCode') || ''
    let sscExt = getCurrentQuery('sscExt') || ''
    setYiqianbaoAuthCode(authCode)
    setYiqianbaoSscExt(sscExt)
    setYiqianbaoSource('wechat')
    console.log('>>>>>>authCode', authCode)
    console.log('>>>>>>sscExt', sscExt)
    // setCountYqb(getCountYqb() + '1')
    if (getMBankAuth()) {
      const data:any = {
        authCode: authCode
      }
      try {
        const res = await httpHelper.getAuthLogin(data, 'authorize/yiqianbao')
        console.log('yiqianbao=' + JSON.stringify(res))
        // 这里苹果goTo有问题
        // this.goTo(res, 9, 15)
        location.replace(`${httpHelper.mRoot}`)
      } catch (err) {
        console.log('yiqianbao err=' + JSON.stringify(err))
        if (err.code === 104) {
          location.replace(`/bind?smstype=9&grantType=15`)
        }
        // this.goTo(err, 9, 15)
        this.$toasted.show(err.msg)
      }
    } else {
      this.showLogin = true
    }
  }
}
